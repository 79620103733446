import * as React from "react";
import { useState, useEffect } from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import Drawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import MenuIcon from "@mui/icons-material/Menu";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import { Link } from "react-router-dom";
import logo from "../../images/icons/logo.svg";
import coloredLogo from "../../images/icons/colored-logo.svg";
import styles from "../../styles/Layout.module.css";
import { useScroll } from "../../customHooks/useScroll";

const Header = () => {
  const drawerWidth = 240;
  const navItems = [
    { name: "DINING", route: "/#dining" },
    { name: "CHALETS", route: "/#chalets" },
    { name: "GALLERY", route: "/#gallery" },
  ];

  const [mobileOpen, setMobileOpen] = React.useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const drawer = (
    <Box onClick={handleDrawerToggle} sx={{ textAlign: "center" }}>
      <Typography variant="h6" sx={{ my: 2 }} component={Link} to="/">
        <img src={coloredLogo} width={"200"} height={"50"} />
      </Typography>
      <Divider />
      <List>
        {navItems.map((item, index) => (
          <ListItem key={index} disablePadding>
            <ListItemButton
              className={styles.link}
              sx={{ textAlign: "center" }}
              component={Link}
              to={item.route}
            >
              <ListItemText
                // primary={item.name }
                primary={
                  <Typography
                    variant="body2"
                    style={{ color: "#000", textAlign: "left" }}
                  >
                    {item.name}{" "}
                  </Typography>
                }
              />
            </ListItemButton>
          </ListItem>
        ))}

        <ListItem disablePadding>
          <ListItemButton
            className={styles.link}
            sx={{ textAlign: "center" }}
            component={Link}
            to={"#contact"}
          >
            <ListItemText
              primary={
                <Typography
                  variant="body2"
                  style={{ color: "#fff", background: "#0B46C1", padding: 10 }}
                >
                  CONTACT US
                </Typography>
              }
            />
          </ListItemButton>
        </ListItem>
      </List>
    </Box>
  );

  const [show, setShow] = useState(true);
  const [lastScrollY, setLastScrollY] = useState(0);
  const [top, setTop] = useState(true);
  const controlNavbar = () => {
    if (typeof window !== "undefined") {
      if (window.scrollY < 200) {
        setTop(true);
      } else {
        setTop(false);
      }
      if (window.scrollY > lastScrollY) {
        // if scroll down hide the navbar
        setShow(false);
      } else {
        // if scroll up show the navbar
        setShow(true);
      }

      // remember current page location to use in the next move
      setLastScrollY(window.scrollY);
    }
  };
  useEffect(() => {
    if (typeof window !== "undefined") {
      window.addEventListener("scroll", controlNavbar);

      // cleanup function
      return () => {
        window.removeEventListener("scroll", controlNavbar);
      };
    }
  }, [lastScrollY]);

  return (
    <Box sx={{ display: "flex" }}>
      <AppBar
        component="nav"
        style={{
          // backgroundColor: "transparent",
          boxShadow: "none",
          padding: 20,
          marginTop: !show && "-120px",
          transition: "all 1s",
          backgroundColor: top ? "transparent" : "#0B46C1",
        }}
      >
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            sx={{ mr: 2, display: { sm: "none" } }}
          >
            <MenuIcon />
          </IconButton>
          <Typography
            variant="h6"
            component={Link}
            to="/"
            sx={{ flexGrow: 1, display: { xs: "none", sm: "block" } }}
          >
            <img src={logo} />
          </Typography>
          <Box
            sx={{ display: { xs: "none", sm: "flex" } }}
            style={{
              width: "50%",
              justifyContent: "space-around",
            }}
          >
            {navItems.map((item, index) => (
              <Button
                className={styles.link}
                key={index}
                // sx={{ color: "#fff" }}
                // component={Link}
                // to={item.route}
              >
                <a
                  style={{
                    color: "#fff",
                    textDecoration: "none",
                  }}
                  href={item.route}
                >
                  {" "}
                  {item.name}
                </a>
              </Button>
            ))}

            <Button className={styles.link} sx={{}}>
              <a
                style={{
                  color: "#fff",
                  background: "#0B46C1",
                  padding: "15px 60px 15px 60px",
                  textDecoration: "none",
                }}
                href="/#contact"
              >
                CONTACT
              </a>
              {/* CONTACT US */}
            </Button>
          </Box>
        </Toolbar>
      </AppBar>
      <Box component="nav">
        <Drawer
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { xs: "block", sm: "none" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
            },
          }}
        >
          {drawer}
        </Drawer>
      </Box>
    </Box>
  );
};

export default Header;
