import {
  BrowserRouter as Router,
  Routes,
  Route,
  useLocation,
} from "react-router-dom";

import { useLayoutEffect } from "react";

import "./App.css";
import Layout from "./components/layout";
import Home from "./pages/Home";
import Book from "./pages/Book";
import Rooms from "./pages/Rooms";
import Room from "./pages/Room";
function App() {
  const Wrapper = ({ children }) => {
    const location = useLocation();
    useLayoutEffect(() => {
      document.documentElement.scrollTo(0, 0);
    }, [location.pathname]);
    return children;
  };
  return (
    <Router>
      <Wrapper>
        <Layout>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/rooms" element={<Rooms />} />
            <Route path="/book/:id" element={<Book />} />
            <Route path="/room/:id" element={<Room />} />
          </Routes>
        </Layout>
      </Wrapper>
    </Router>
  );
}

export default App;
