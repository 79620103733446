import { Grid } from "@mui/material";
import React from "react";
import { Link } from "react-router-dom";
import styles from "../styles/Rooms.module.css";
const RoomCard = ({ state, room, filterForm }) => {
  return (
    <Grid
      item
      xl={4}
      lg={4}
      md={4}
      sm={12}
      xs={12}
      style={{ position: "relative" }}
    >
      {room.available == 0 && (
        <div style={{ position: "absolute", right: 20, top: 30 }}>
          NOT AVAILABLE
        </div>
      )}
      <div className={styles.cardSection}>
        <div style={{ zIndex: 100 }}>
          <Link
            state={
              filterForm && {
                from: filterForm.from,
                to: filterForm.to,
                id: room.id,
              }
            }
            to={`/room/${room.id}`}
          >
            <img
              src={`${process.env.REACT_APP_API_BASE_URL_ATTACHMENTS}/rooms/${room?.image}`}
              alt="image"
              className={styles.img}
            />
          </Link>
          <h1 className={styles.roomName}>{room?.name}</h1>
          <p className={styles.roomDescription}>{room?.description}</p>
        </div>
        <div className={styles.roomCardFooter}>
          <div className={styles.roomPriceSection}>
            <h1 className={styles.roomPrice}>{room?.price}</h1>
            <strong style={{ marginLeft: 10 }} className={styles.roomPriceTag}>
              USD/NIGHT
            </strong>
          </div>

          <Link
            className={styles.book}
            state={
              filterForm && {
                from: filterForm.from,
                to: filterForm.to,
                id: room.id,
              }
            }
            to={
              filterForm &&
              !Object.values(filterForm).some((x) => x === null || x === "")
                ? `/book/${room.id}`
                : `/room/${room.id}`
            }
          >
            BOOK NOW
          </Link>
        </div>
      </div>
    </Grid>
  );
};

export default RoomCard;
