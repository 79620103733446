import React, { useState } from "react";
import { Box, Button, Container, Grid, Typography } from "@mui/material";
import { Link } from "react-router-dom";
import styles from "../../styles/Layout.module.css";
import Img from "../../images/footer-bg.png";

import TikTok from "../../images/icons/tiktok.svg";
import Fb from "../../images/icons/fb.svg";
import Insta from "../../images/icons/insta.svg";
import Snackbar from "@mui/material/Snackbar";

import MuiAlert from "@mui/material/Alert";
import axios from "axios";

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});
const Footer = () => {
  const navItems = [
    { name: "DINING", route: "/dining" },
    { name: "CHALETS", route: "/#chalets" },
    { name: "GALLERY", route: "/#gallery" },
    { name: "CONTACT US", route: "/#contact" },
  ];

  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [email, setEmail] = useState("");

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };

  const submit = () => {
    setLoading(true);
    axios
      .post(`${process.env.REACT_APP_API_BASE_URL}/user/subscribe`, { email })
      .then((res) => {
        setOpen(true);
        setEmail("");
      })
      .catch((error) => {})
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <Box
      sx={{
        // width: "100vw",
        minHeight: "400px",
        maxHeight: "800px",

        // position: "fixed",
        // left: 0,
        // bottom: 0,
        // right: 0,
        backgroundImage: `url(${Img})`,
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",

        paddingTop: 20,
        paddingRight: 1,
        paddingBottom: 10,
        paddingLeft: 10,
      }}
    >
      <Grid container>
        <Grid
          item
          xl={3}
          lg={3}
          md={12}
          sm={12}
          xs={12}
          sx={{
            display: "flex",
            flexDirection: "column",
          }}
        >
          {navItems.map((item, index) => (
            <Button
              className={styles.footerLink}
              key={index}
              sx={{ color: "#fff", justifyContent: "flex-start" }}
              // component={Link}
              // to={item.route}
            >
              {/* {item.name} */}

              <a
                style={{
                  color: "#fff",
                  justifyContent: "flex-start",
                  textDecoration: "none",
                  textAlign: "left",
                }}
                href={item.route}
              >
                {" "}
                {item.name}
              </a>
            </Button>
          ))}
        </Grid>
        <Grid
          item
          xl={5}
          lg={5}
          md={12}
          sm={12}
          xs={12}
          sx={{
            display: "flex",
            flexDirection: "column",
            maxWidth: "100%",
          }}
        >
          <Typography className={styles.footerTitle}>
            {/* {`${new Date().getFullYear()}   DSOFT  `} */}
            STAY UP TO DATE
          </Typography>
          <input
            value={email}
            className={styles.footerInput}
            placeholder="FULL NAME"
            onChange={(e) => setEmail(e.target.value)}
          />
          {loading ? (
            <div className="loader"></div>
          ) : (
            <Button
              onClick={submit}
              className={styles.subscribeBtn}
              variant="contained"
              sx={{
                maxWidth: 235,
                marginLeft: "auto",
                marginTop: 4,
                height: 62,
              }}
            >
              SUBSCRIBE
            </Button>
          )}
          <div style={{ marginTop: 40, display: "flex" }}>
            <a
              href="https://www.tiktok.com/@gondolamarine"
              target="_blank"
              style={{ marginRight: 100 }}
            >
              <img src={TikTok} width="35" height="35" />
            </a>
            <a
              href="https://www.facebook.com/GondolaMarine/"
              target="_blank"
              style={{ marginRight: 100 }}
            >
              <img src={Fb} width="35" height="35" />
            </a>
            <a href="https://www.instagram.com/gondolamarine" target="_blank">
              <img src={Insta} width="35" height="35" />
            </a>
          </div>
        </Grid>
      </Grid>

      <Snackbar open={open} autoHideDuration={3000} onClose={handleClose}>
        <Alert onClose={handleClose} severity="success" sx={{ width: "100%" }}>
          Successfully subscribed
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default Footer;
