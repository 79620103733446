import axios from "axios";
import { useEffect, useState } from "react";

export default function useFetch(url, params = {}, dependencies = []) {
  console.log(dependencies, ...dependencies);
  const [data, setData] = useState([]);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    setLoading(true);
    axios
      .get(url, {
        params: params,
      })
      .then((res) => {
        setData(res.data);
      })
      .catch((err) => {
        setError(err);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [url, ...dependencies]);
  return { data, error, loading };
}
