import { Grid } from "@mui/material";
import React, { useState } from "react";
import RoomCard from "../../components/RoomCard";
import useFetch from "../../customHooks/useFetch";
import styles from "../../styles/Home.module.css";
import arrow from "../../images/icons/long-arrow-forward.svg";
import { Link } from "react-router-dom";
const Chalets = () => {
  const { data, error, loading } = useFetch(
    `${process.env.REACT_APP_API_BASE_URL}/data/rooms`,
    {
      limit: 3,
    }
  );

  return (
    <div style={{ paddingLeft: 20, paddingRight: 40 }} id="chalets">
      <h1 className={styles.title}>CHALETS</h1>

      <Grid container spacing={5}>
        {data?.map((room, index) => (
          <RoomCard key={index} room={room} />
        ))}
      </Grid>
      <Link className={styles.viewBtn} to={`/rooms`}>
        <span>VIEW ALL ROOMS</span>

        <img src={arrow} />
      </Link>
    </div>
  );
};

export default Chalets;
